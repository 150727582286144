<template>
  <fragment>
    <v-row>
      <v-card
        class="my-2"
        width="100%"
      >
        <v-card-title primary-title>
          {{ $t('lbl.pax') }} {{ pos }} -
          <span v-if="cotizadorPaxFlightSelect[index].type === 'adult'"> {{ $t('lbl.adulto') }}</span>
          <span v-else-if="cotizadorPaxFlightSelect[index].type === 'child'"> {{ $t('lbl.child') }}</span>
          <span v-else-if="cotizadorPaxFlightSelect[index].type === 'infant'"> {{ $t('lbl.infat') }}</span>
        </v-card-title>
        <v-container grid-list-xs>
          <v-row class="mb-1">
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="cotizadorPaxFlightSelect[index].name"
                :label="`${$t('lbl.name')} *`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="cotizadorPaxFlightSelect[index].apellidos"
                :label="`${$t('lbl.apellidos')} *`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="cotizadorPaxFlightSelect[index].nameSecound"
                :label="$t('lbl.nameSecond')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <vc-date-picker
                v-model="cotizadorPaxFlightSelect[index].birthday"
                outlined
                dense
                mode="date"
                :model-config="modelConfig"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    :label="$t('lbl.birthday')"
                    outlined
                    dense
                    hide-details
                    :value="inputValue"
                    v-on="inputEvents"
                  >
                  </v-text-field>
                </template>
              </vc-date-picker>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="cotizadorPaxFlightSelect[index].nationality"
                class="pt-2"
                :items="itemsNationality"
                :search-input.sync="searchNationality"
                item-text="name"
                item-value="code"
                hide-selected
                :label="`${$t('lbl.nationality')} *`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
                return-object
                @change="searchPrefijo"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.nationality') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-radio-group
                v-model="cotizadorPaxFlightSelect[index].sex"
                row
                class="px-4"
              >
                <v-radio
                  :label="$t('lbl.sexMasc')"
                  value="masc"
                ></v-radio>
                <v-radio
                  :label="$t('lbl.sexFem')"
                  value="fem"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="cotizadorPaxFlightSelect[index].noDocument"
                :label="`${$t('lbl.noDocument')} *`"
                outlined
                dense
                hide-details="auto"
                :rules="alphanumericRules"
                @input="
                  val =>
                    (cotizadorPaxFlightSelect[index].noDocument = cotizadorPaxFlightSelect[
                      index
                    ].noDocument.toUpperCase())
                "
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <vc-date-picker
                v-model="cotizadorPaxFlightSelect[index].dateCaduque"
                outlined
                dense
                mode="date"
                :model-config="modelConfig"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    :label="`${$t('lbl.dateCaduque')} *`"
                    outlined
                    dense
                    :value="inputValue"
                    hide-details="auto"
                    :rules="textRequiered"
                    v-on="inputEvents"
                  >
                  </v-text-field>
                </template>
              </vc-date-picker>
            </v-col>
            <v-col
              cols="12"
              class="pt-0 px-4"
            >
              <a
                v-if="!verCardFidelidad"
                style="cursor: pointer"
                @click="showCardFidelidad"
              >
                <p class="mb-0 p12Mine">{{ $t('lbl.addCardFidelidad') }}</p>
              </a>
              <a
                v-else
                style="cursor: pointer"
                @click="showCardFidelidad"
              >
                <p class="mb-0 p12Mine">{{ $t('lbl.hideCardFidelidad') }}</p>
              </a>
            </v-col>
            <v-col
              v-if="verCardFidelidad"
              cols="12"
              md="6"
            >
              <!--<v-select
                v-model="cotizadorPaxFlightSelect[index].cardFidelidad"
                :label="$t('lbl.cardFidelidad')"
                :items="cardsFidelidad"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
              ></v-select>-->
              <v-autocomplete
                v-model="cotizadorPaxFlightSelect[index].cardFidelidad"
                :items="itemsCardFidelidad"
                :search-input.sync="searchCardFidelidad"
                hide-details
                hide-selected
                :label="$t('lbl.cardFidelidad')"
                outlined
                dense
                item-text="name"
                item-value="id"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.cardFidelidad') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="verCardFidelidad"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="cotizadorPaxFlightSelect[index].noCardFidelidad"
                :label="$t('lbl.noCardFidelidad')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="pb-0 px-4"
            >
              <h4>{{ $t('lbl.contactPasajero') }}</h4>
            </v-col>
            <v-col
              v-if="!isSearchCountry"
              cols="12"
              md="2"
            >
              <!--<v-text-field
                v-model="cotizadorPaxFlightSelect[index].contact_code_phone"
                :label="$t('register.codidoPais')"
                outlined
                dense
                hide-details
              ></v-text-field>
              <v-select
                v-model="cotizadorPaxFlightSelect[index].contact_code_phone"
                :label="$t('register.codidoPais')"
                :items="countriesCallingCodes"
                outlined
                dense
                hide-details
              ></v-select>-->
              <v-autocomplete
                v-model="cotizadorPaxFlightSelect[index].contact_code_phone"
                prefix="+"
                :items="itemsFilterCodePhone"
                :search-input.sync="searchFilterCodePhone"
                hide-details="auto"
                hide-selected
                :label="$t('register.codidoPais')"
                outlined
                dense
                item-text="code_phone"
                item-value="code_phone"
                single-line
                :rules="textRequiered"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('register.codidoPais') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.code_phone"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.code_phone }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="cotizadorPaxFlightSelect[index].contact_phone"
                :label="`${$t('lbl.telefono')} *`"
                outlined
                dense
                hide-details="auto"
                :rules="phoneRules"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="cotizadorPaxFlightSelect[index].contact_email"
                :label="`${$t('lbl.email')} *`"
                outlined
                dense
                hide-details="auto"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="showCodeOFA"
              cols="12"
              md="6"
            >
              <v-select
                v-model="cotizadorPaxFlightSelect[index].contact_ofac"
                :label="$t('lbl.codeOFAC')"
                :items="codesOfac"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>

    <v-row>
      <v-card width="100%">
        <v-card-title
          primary-title
          class="pb-3"
        >
          {{ $t('lbl.contactEmergency') }}
          <v-tooltip
            v-if="
              (cotizadorPaxFlightSelect[index].contact_emergency_name ||
                cotizadorPaxFlightSelect[index].contact_emergency_phone ||
                cotizadorPaxFlightSelect[index].contact_emergency_email) &&
                cantPaxs > 1
            "
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="estableceContactEmergency"
              >
                <v-icon
                  color="primary"
                  class="mx-2"
                >
                  {{ icons.mdiArrowDownThinCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              <div class="d-block mb-2">
                <div class="d-flex justify-start align-center">
                  <p class="mb-1 mt-2">
                    <b>{{ $t('lbl.estableceContactEmergency') }}</b>
                  </p>
                </div>
              </div>
            </span>
          </v-tooltip>
        </v-card-title>
        <v-container
          grid-list-xs
          class="px-5 pt-0"
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="cotizadorPaxFlightSelect[index].contact_emergency_name"
                :label="$t('lbl.nameSurname')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="cotizadorPaxFlightSelect[index].contact_emergency_phone"
                :label="$t('lbl.telefono')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="cotizadorPaxFlightSelect[index].contact_emergency_email"
                :label="$t('lbl.email')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronLeft,
  mdiBagSuitcase,
  mdiBagPersonal,
  mdiInformation,
  mdiInformationVariant,
  mdiBagSuitcaseOffOutline,
  mdiTimerOutline,
  mdiArrowDownThinCircleOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      itemsOrigen: [],
      isLoading: false,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      cantPaxs: 0,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      iataOrigen: null,
      iataOrig: null,
      iataName: null,
      iataDestino: null,
      iataDest: null,
      iataNameD: null,
      aeroline: null,
      flight: null,
      tarifa: null,
      destino: null,
      vrad: 0,
      value: null,
      value1: null,
      model: {},
      countries: [],
      countriesCallingCodes: [],
      itemsFilterCodePhone: [],
      searchFilterCodePhone: null,
      isSearchCountry: false,
      itemsNationality: [],
      searchNationality: null,
      verCardFidelidad: false,
      itemsCardFidelidad: [],
      searchCardFidelidad: null,
      cardsFidelidad: [],
      codesOfac: [],
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronLeft,
        mdiBagSuitcase,
        mdiBagPersonal,
        mdiInformation,
        mdiInformationVariant,
        mdiBagSuitcaseOffOutline,
        mdiTimerOutline,
        mdiArrowDownThinCircleOutline,
      },
      showCodeOFA: false,
      codesCountry: [],
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [
        v => !!v || 'Campo requerido',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail debe ser válido',
      ],
      alphanumericRules: [
        v => !!v || 'Campo requerido',
        v => /^[a-zA-Z0-9]+$/.test(v) || 'Debe ser solo números y letras',
      ],
      phoneRules: [v => !!v || 'Campo requerido', v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      searchingFlightCotizador: state => state.app.searchingFlightCotizador,
      cotizadorFlightSelect: state => state.app.cotizadorFlightSelect,
      cotizadorPaxFlightSelect: state => state.app.cotizadorPaxFlightSelect,
    }),

    // eslint-disable-next-line vue/return-in-computed-property
    sortedArray() {
      let sortedRecipes = this.itemsFilterCodePhone
      // eslint-disable-next-line no-unused-vars
      sortedRecipes = sortedRecipes.sort((a, b) => {
        const fa = a.code_phone
        const fb = b.code_phone
        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }

        return 0
      })
    },
  },

  watch: {
    searchNationality(val) {
      this.filterNationality(val.toLowerCase())
    },
    searchCardFidelidad(val) {
      this.filterCardFidelidad(val.toLowerCase())
    },
    searchFilterCodePhone(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCodePhone(val.toLowerCase())
      } else {
        this.itemsFilterCodePhone = []
      }
    },
  },
  created() {
    this.getCountries()
    this.getCardsFidelidad()
    this.getCodesOFAC()
    this.getCountriesCallingCodes()

    this.cantPaxs = this.cotizadorFlight.cant_adults + this.cotizadorFlight.cant_ninnos + this.cotizadorFlight.cant_infant
  },
  methods: {
    ...mapMutations([
      'deleteDestinoCotizadorFligth',
      'setSearchingFlightCotizador',
      'setContactEmergencyAllCotizadorFlight',
    ]),
    filterNationality(v) {
      this.itemsNationality = []
      if (v === '') {
        this.itemsNationality = []
      } else {
        this.itemsNationality = this.countries.filter(e => e.name.toLowerCase())
      }
    },
    filterCardFidelidad() {
      this.itemsCardFidelidad = this.cardsFidelidad.filter(e => e.name.toLowerCase())

      /* this.itemsCardFidelidad = []
      if (v === '') {
        this.itemsCardFidelidad = []
      } else {
        this.itemsCardFidelidad = this.cardsFidelidad.filter(e => e.name.toLowerCase())
      } */
    },
    filterCodePhone(v) {
      this.itemsFilterCodePhone = []
      if (v === '') {
        this.itemsFilterCodePhone = []
      } else {
        this.itemsFilterCodePhone = this.countriesCallingCodes.filter(e => e.code_phone)
      }
    },
    getCountries() {
      this.axios
        .get('countries', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.countries = res.data.data.data
        })
    },
    getCountriesCallingCodes() {
      this.axios
        .get('https://restcountries.com/v2/all?fields=name,alpha2Code,callingCodes', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log(res.data)
          res.data.forEach(element => {
            const codes = element.callingCodes
            codes.forEach(elementCode => {
              if (!this.countriesCallingCodes.includes(elementCode)) {
                // eslint-disable-next-line radix
                this.countriesCallingCodes.push({
                  // eslint-disable-next-line radix
                  code_phone: parseInt(elementCode),
                  country_code: element.alpha2Code.toLowerCase(),
                })
              }
            })
          })
        })

      // .finally(() => this.countriesCallingCodes.sort())
    },
    searchPrefijo() {
      if (this.cotizadorPaxFlightSelect[this.index].nationality) {
        if (this.cotizadorPaxFlightSelect[this.index].nationality.code !== '') {
          // OFA
          if (
            this.cotizadorPaxFlightSelect[this.index].nationality.code === 'us'
            || this.cotizadorPaxFlightSelect[this.index].nationality.code === 'pr'
          ) {
            this.showCodeOFA = true
          } else {
            this.showCodeOFA = false
          }
          this.cotizadorPaxFlightSelect[this.index].contact_code_phone = ''
          this.isSearchCountry = true

          this.itemsFilterCodePhone = this.countriesCallingCodes.filter(
            e => e.country_code.toLowerCase() === this.cotizadorPaxFlightSelect[this.index].nationality.code,
          )
          // eslint-disable-next-line prefer-destructuring
          this.cotizadorPaxFlightSelect[this.index].contact_code_phone = this.countriesCallingCodes.filter(
            e => e.country_code.toLowerCase() === this.cotizadorPaxFlightSelect[this.index].nationality.code,
          )[0].code_phone

          setTimeout(() => {
            this.isSearchCountry = false
          }, 100)

          /* this.axios
            .get(`https://restcountries.com/v3.1/alpha?codes=${this.cotizadorPaxFlightSelect[this.index].nationality.code}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              // cotizadorPaxFlightSelect[index].contact_code_phone
              this.codesCountry = []
              if (res.data.length > 0) {
                // console.log(res.data[0].idd)
                if (res.data[0].idd.suffixes.length > 0) {
                  res.data[0].idd.suffixes.forEach(element => {
                    this.codesCountry.push(`${res.data[0].idd.root}${element}`)
                  })
                  // eslint-disable-next-line prefer-destructuring
                  this.cotizadorPaxFlightSelect[this.index].contact_code_phone = this.codesCountry[0]
                }

                // this.cotizadorPaxFlightSelect[this.index].contact_code_phone = res.data[0].idd.root
                // if (res.data[0].idd.suffixes.length > 0) {
                //   this.cotizadorPaxFlightSelect[this.index].contact_code_phone = `${res.data[0].idd.root}${res.data[0].idd.suffixes[0]}`
                // }
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.isSearchCountry = false)) */
        }
      } else {
        this.showCodeOFA = false
      }
    },
    getCardsFidelidad() {
      const aerolineId = this.cotizadorFlightSelect.item_ida.flight.aerolinea_id
        ? this.cotizadorFlightSelect.item_ida.flight.aerolinea_id
        : -1
      this.axios
        .get(`card-fidelidad?&per_page=1000&aeroline_id=${aerolineId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.cardsFidelidad = res.data.data
        })
    },
    getCodesOFAC() {
      this.axios
        .get('codes-ofac?&per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          result.forEach(element => {
            this.codesOfac.push({ id: element.id, name: `${element.code} ${element.name}` })
          })
        })
    },
    showCardFidelidad() {
      this.verCardFidelidad = !this.verCardFidelidad
    },
    estableceContactEmergency() {
      this.setContactEmergencyAllCotizadorFlight({
        name: this.cotizadorPaxFlightSelect[this.index].contact_emergency_name,
        phone: this.cotizadorPaxFlightSelect[this.index].contact_emergency_phone,
        email: this.cotizadorPaxFlightSelect[this.index].contact_emergency_email,
      })
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
