<template>
  <fragment>
    <FPasajero
      v-for="(pax, index) in paxs"
      :key="index"
      :index="index"
      :pos="index + 1"
    />

    <!--<v-row class=" pt-2">
      <v-card width="100%">
        <v-card-title
          primary-title
          class="pb-3"
        >
          {{ $t('lbl.reserveConfirm') }}
        </v-card-title>
        <v-container
          grid-list-xs
          class="px-5 pt-0"
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="model.reserve_confirm_ticket_number"
                :label="$t('lbl.ticketNumber')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="model.reserve_confirm_locator"
                :label="$t('lbl.locator')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="model.reserve_confirm_pnr"
                :label="$t('lbl.pnr')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>-->

    <!-- desktop -->
    <v-row class="mt-3">
      <v-card
        width="100%"
        class="my-2"
      >
        <v-card-title
          primary-title
          class="pb-3"
        >
          {{ $t('lbl.finallyReserve') }}
        </v-card-title>
        <v-container
          grid-list-xs
          class="px-5 pt-0"
        >
          <v-row>
            <v-col cols="12">
              <p class="mb-0">
                {{ $t('lbl.finallyReserveText1') }}
              </p>
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              <p class="mb-0">
                <b>{{ $t('lbl.note') }}:</b> {{ $t('lbl.finallyReserveText2') }}
              </p>
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-divider></v-divider>
            </v-col>
            <v-col
              cols="12"
              md="8"
              class="pt-0 d-flex justify-start align-center"
            >
              <div class="d-flex justify-start align-center">
                <v-icon color="success">
                  {{ icons.mdiTimerOutline }}
                </v-icon>
                <p class="mb-0 success--text">
                  Ticket Time Limit: 2 días
                </p>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="pt-0"
            >
              <v-btn
                color="primary"
                width="100%"
                :loading="loading"
                @click="reserveNow"
              >
                {{ $t('btn.reserveNow') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="mb-0 pt-0"
            >
              <div
                class="ma-1 pa-3"
                style="background-color:#f4f4f4;"
              >
                <p class="mb-0">
                  {{ $t('lbl.finallyReserveText3') }}
                  <a>{{ $t('lbl.rulesTarifa') }}</a>
                  {{ $t('lbl.finallyReserveText4') }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronLeft,
  mdiBagSuitcase,
  mdiBagPersonal,
  mdiInformation,
  mdiInformationVariant,
  mdiBagSuitcaseOffOutline,
  mdiTimerOutline,
} from '@mdi/js'

import FPasajero from './FPasajero.vue'

export default {
  components: {
    FPasajero,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      itemsOrigen: [],
      isLoading: false,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      model: {},
      paxs: [],
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronLeft,
        mdiBagSuitcase,
        mdiBagPersonal,
        mdiInformation,
        mdiInformationVariant,
        mdiBagSuitcaseOffOutline,
        mdiTimerOutline,
      },
      user: {},
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      searchingFlightCotizador: state => state.app.searchingFlightCotizador,
      cotizadorFlightSelect: state => state.app.cotizadorFlightSelect,
      cotizadorPaxFlightSelect: state => state.app.cotizadorPaxFlightSelect,
      cotizadorFlightSelectMarkups: state => state.app.cotizadorFlightSelectMarkups,
    }),
  },
  created() {
    this.profile()

    // console.log(this.cotizadorFlightSelect)
    // eslint-disable-next-line camelcase
    let i = 0
    // eslint-disable-next-line camelcase
    while (i !== this.cotizadorFlightSelect.cant_adults) {
      // eslint-disable-next-line no-plusplus, camelcase
      i++
      this.paxs.push({
        type: 'adult',
        pos: i,
        name: null,
        apellidos: null,
        nameSecound: null,
        birthday: null,
        nationality: null,
        sex: null,
        noDocument: null,
        dateCaduque: null,
        cardFidelidad: null,
        noCardFidelidad: null,
        contact_phone: null,
        contact_email: null,
        contact_ofac: null,
        contact_emergency_name: null,
        contact_emergency_phone: null,
        contact_emergency_email: null,
      })
    }

    // eslint-disable-next-line camelcase
    let j = 0
    // eslint-disable-next-line camelcase
    while (j !== this.cotizadorFlightSelect.cant_ninnos) {
      // eslint-disable-next-line no-plusplus, camelcase
      j++
      this.paxs.push({
        type: 'child',
        pos: j,
        name: null,
        apellidos: null,
        nameSecound: null,
        birthday: null,
        nationality: null,
        sex: null,
        noDocument: null,
        dateCaduque: null,
        cardFidelidad: null,
        noCardFidelidad: null,
        contact_phone: null,
        contact_email: null,
        contact_ofac: null,
        contact_emergency_name: null,
        contact_emergency_phone: null,
        contact_emergency_email: null,
      })
    }

    // eslint-disable-next-line camelcase
    let k = 0
    // eslint-disable-next-line camelcase
    while (k !== this.cotizadorFlightSelect.cant_infant) {
      // eslint-disable-next-line no-plusplus, camelcase
      k++
      this.paxs.push({
        type: 'infant',
        pos: k,
        name: null,
        apellidos: null,
        nameSecound: null,
        birthday: null,
        nationality: null,
        sex: null,
        noDocument: null,
        dateCaduque: null,
        cardFidelidad: null,
        noCardFidelidad: null,
        contact_phone: null,
        contact_email: null,
        contact_ofac: null,
        contact_emergency_name: null,
        contact_emergency_phone: null,
        contact_emergency_email: null,
      })
    }

    this.setCotizadorPaxFlightSelect(this.paxs)
  },
  methods: {
    ...mapMutations(['deleteDestinoCotizadorFligth', 'setSearchingFlightCotizador', 'setCotizadorPaxFlightSelect']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          this.user = response.data.data
        })
        .catch(error => console.log(error))
    },
    reserveNow() {
      // console.log(this.model)
      this.loading = true
      // eslint-disable-next-line camelcase
      let ticket_number = ''
      if (this.model.reserve_confirm_ticket_number) {
        // eslint-disable-next-line camelcase
        ticket_number = this.model.reserve_confirm_ticket_number
      }
      // eslint-disable-next-line camelcase
      let locator = ''
      if (this.model.reserve_confirm_locator) {
        // eslint-disable-next-line camelcase
        locator = this.model.reserve_confirm_locator
      }
      let pnr = ''
      if (this.model.reserve_confirm_pnr) {
        // eslint-disable-next-line camelcase
        pnr = this.model.reserve_confirm_pnr
      }

      const itemsReserve = []
      if (this.cotizadorFlightSelect.onlyIda) {
        itemsReserve.push({
          flights: {
            id_contrate: this.cotizadorFlightSelect.item_ida.id_contrate,
            flight: this.cotizadorFlightSelect.item_ida.flight,
            tarifas_contrate: this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa],
          },
        })
      } else if (!this.cotizadorFlightSelect.onlyIda && !this.cotizadorFlightSelect.destinosMultiples) {
        itemsReserve.push({
          flights: {
            id_contrate: this.cotizadorFlightSelect.item_ida.id_contrate,
            flight: this.cotizadorFlightSelect.item_ida.flight,
            tarifas_contrate: this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa],
          },
        })
        itemsReserve.push({
          flights: {
            id_contrate: this.cotizadorFlightSelect.item_regreso.id_contrate,
            flight: this.cotizadorFlightSelect.item_regreso.flight,
            tarifas_contrate: this.cotizadorFlightSelect.item_regreso.tarifas_contrate[this.cotizadorFlightSelect.indRegreso],
          },
        })
      } else if (this.cotizadorFlightSelect.destinosMultiples) {
        this.cotizadorFlightSelect.destinos.forEach(element => {
          itemsReserve.push({
            origen: element.origen,
            destino: element.destino,
            idOrigen: element.idOrigen,
            idDestino: element.idDestino,
            from: element.from,
            to: element.to,
            flights: {
              id_contrate: element.flights[element.indexTarifa].id_contrate,
              from: element.flights[element.indexTarifa].from,
              to: element.flights[element.indexTarifa].to,
              flight: element.flights[element.indexTarifa].flight,
              tarifas_contrate: element.flights[element.indexTarifa].tarifas_contrate[element.indTarifa],
            },
          })
        })
      }
      const jsonReserve = {
        cant_adults: this.cotizadorFlightSelect.cant_adults,
        cant_ninnos: this.cotizadorFlightSelect.cant_ninnos,
        cant_infant: this.cotizadorFlightSelect.cant_infant,
        onlyIda: this.cotizadorFlightSelect.onlyIda,
        destinosMultiples: this.cotizadorFlightSelect.destinosMultiples,
        destinos: this.cotizadorFlightSelect.destinos,
        items: itemsReserve,
      }
      const json = {
        product: 'flights',
        data_client: this.cotizadorPaxFlightSelect,
        data_markups: this.cotizadorFlightSelectMarkups,
        data_service: jsonReserve,
        data_reserva: {
          ticket_number,
          locator,
          pnr,
        },
        lang: this.$i18n.locale,
        user_id: this.user.id,
      }
      let procesar = true
      this.cotizadorPaxFlightSelect.forEach(element => {
        if (element.name === null || element.apellidos === null || element.nationality === null
            || element.sex === null || element.noDocument === null || element.dateCaduque === null || element.contact_email === null) {
          procesar = false
        }
      })

      if (procesar) {
        const formData = new FormData()
        formData.append('data', JSON.stringify(json))

        this.axios
          .post('reservations/generate', formData,
            { responseType: 'arraybuffer' }, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
          .then(res => {
            // console.log(res)
            let filename = 'cotization'
            if (this.cotizadorPaxFlightSelect.length > 0) {
              filename = `${this.cotizadorPaxFlightSelect[0].name} ${this.cotizadorPaxFlightSelect[0].apellidos}_Vuelos_${this.$moment().format('M-D-Y')}`
              filename = filename.replace(/ /g, '-')
            }

            this.downloadFile(res, filename)

            this.$router.push({ name: 'success-cotitation' })

            /* if (res.data.success) {
              this.$router.push({ name: 'reservations' })
            } */
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
        this.loading = false
      }
    },
    downloadFile(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine>div>p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine>div>h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app>div>main>div>div>div>div>div>div>div>div.col-md-8.col-12>div:nth-child(3)>div>div.container.grid-list-xs>div>div:nth-child(6)>div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
